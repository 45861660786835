import { Link } from 'gatsby';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import React from 'react';

const Content = styled.div`
  max-width: 860px;
  padding: 1rem 1.0875rem;
  font-size: 1.2rem;
  p {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
`;

const NavLink = styled(Link)`
  color: black;
  margin-left: 15px;
  text-decoration: none;
  display: inline-block;
  position: relative;

  ::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.8);
    transform-origin: bottom right;
    transition: transform 0.4s cubic-bezier(0.86, 0, 0.07, 1);
  }

  :hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
`;

const SocialLink = styled.a`
  color: black;
  margin-left: 15px;
  text-decoration: none;
  display: inline-block;
  position: relative;

  ::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.8);
    transform-origin: bottom right;
    transition: transform 0.4s cubic-bezier(0.86, 0, 0.07, 1);
  }

  :hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
`;

const HomeLink = styled(NavLink)`
  margin-left: 0;
`;

const SiteHeader = styled.header`
  background: transparent;
  display: flex;
  align-content: center;
  justify-content: center;
`;

const Header = ({ siteTitle }) => (
  <SiteHeader>
    <Content>
      <p>
        <HomeLink to="/">{siteTitle}</HomeLink>
        <NavLink
          target="_blank"
          href="https://notion.so/413cf8c0f0e84e358280fc1a8c146e2f"
        >
          Blog
        </NavLink>
        <SocialLink href="https://github.com/a-tokyo" target="_blank">
          GitHub
        </SocialLink>
        <SocialLink href="https://twitter.com/ahmad_tokyo" target="_blank">
          Twitter
        </SocialLink>
        <SocialLink href="https://linkedin.com/in/a-tarek" target="_blank">
          LinkedIn
        </SocialLink>
        <SocialLink href="https://calendly.com/a-tokyo" target="_blank">
          Calendly
        </SocialLink>
        <SocialLink
          href={`mailto:ahmed.tokyo1@gmail.com?subject=ahmedtokyo.com`}
          target="_blank"
        >
          Email
        </SocialLink>
      </p>
    </Content>
  </SiteHeader>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
